<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="relative z-40 lg:hidden" @close="sidebarOpen = false">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                                 enter-from="opacity-0"
                                 enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
                                 leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80"/>
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                     enter-from="-translate-x-full" enter-to="translate-x-0"
                                     leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                     leave-to="-translate-x-full">
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                             enter-to="opacity-100" leave="ease-in-out duration-300"
                                             leave-from="opacity-100"
                                             leave-to="opacity-0">
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                        <span class="sr-only">Close sidebar</span>
                                        <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                                    </button>
                                </div>
                            </TransitionChild>
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-color px-6 pb-4">
                                <div class="flex h-16 shrink-0 items-center mt-4">
                                    <div :key="getFeedCompany.id" v-if="getFeedCompany.logo?.length>0" class="flex flex-row space-x-2 items-center">
                                        <img :key="getFeedCompany.id" class="max-h-14 max-w-40" :src="getFeedCompany.logo" alt=""/>
                                    </div>
                                </div>
<!--                                <nav class="flex flex-1 flex-col">-->
<!--                                    <ul role="list" class="flex flex-1 flex-col gap-y-7">-->
<!--                                        <li>-->
<!--                                            <ul role="list" class="-mx-2 space-y-1">-->
<!--                                                <li v-for="item in getSidebarNavigations(isLoggedInUserAnAdminOfThisCompany(getFeedCompany.id), route.path)"-->
<!--                                                    :key="item.name">-->
<!--                                                    <nuxt-link :to="item.href" @mousedown="sidebarOpen=false"-->
<!--                                                               :class="[item.href==currentPath ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">-->
<!--                                                        <Icon :name="item.icon"-->
<!--                                                              :class="[item.href==currentPath ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']"-->
<!--                                                              aria-hidden="true"/>-->
<!--                                                        <span>{{ (item.name) }}</span>-->
<!--                                                    </nuxt-link>-->
<!--                                                </li>-->
<!--                                            </ul>-->
<!--                                        </li>-->
<!--                                        <li class="hidden">-->
<!--                                            <div class="text-xs font-semibold leading-6 text-indigo-200">-->
<!--                                                {{ ('Your teams') }}-->
<!--                                            </div>-->
<!--                                            <ul role="list" class="-mx-2 mt-2 space-y-1">-->
<!--                                                <li v-for="team in teams" :key="team.name">-->
<!--                                                    <a :href="team.href"-->
<!--                                                       :class="[team.current ? 'bg-indigo-700 text-white' : 'text-indigo-200 hover:text-white hover:bg-indigo-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">-->
<!--                            <span-->
<!--                                    class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">{{-->
<!--                                team.initial-->
<!--                                }}</span>-->
<!--                                                        <span class="truncate">{{ team.name }}</span>-->
<!--                                                    </a>-->
<!--                                                </li>-->
<!--                                            </ul>-->
<!--                                        </li>-->
<!--                                        <li class="mt-auto">-->
<!--                                            <a @mousedown="onLogout"-->
<!--                                               class="group cursor-pointer -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white">-->
<!--                                                <ArrowRightOnRectangleIcon-->
<!--                                                        class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"-->
<!--                                                        aria-hidden="true"/>-->
<!--                                                {{ ('Sign out') }}-->
<!--                                            </a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </nav>-->
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
        <div>
            <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <div class="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6">
                   <div class="relative flex flex-1 flex-col ">
                      <span v-if="isAddNewContest()" class="font-semibold xsm:text-md text-black line-clamp-1 text-xl">
                          Create New Contest
                       </span>
                     <span v-else class="font-semibold xsm:text-md text-black line-clamp-1 text-xl">{{
                          getTitle()
                        }}</span>

                      <div v-if="!isAddNewContest()" class="text-gray-500 text-sm flex flex-row items-center gap-2">
                        <EventDetails :event="getCurrentClub"/>
                        <div v-if="getCurrentContestStatus === ContestStatusLocal.ResultDeclared" class="flex flex-row gap-2">
                          <div class="flex flex-row items-center gap-1">
                            <span class="px-1">|</span>
                            <Icon name="iconoir:eye-solid" class="text-brand-color"/>
                            <span class="text-brand-color">{{ getViewsCount + " " }}</span>
                          </div>
                          <div class="flex flex-row gap-1 items-center">
                            <span class="px-2">|</span>
                            <Icon name="ic:baseline-image" class="text-brand-color"/>
                            <span class="text-brand-color">{{ getTotalFeedPostCount }}</span>
                          </div>
                        </div>
                        <div v-else-if="getCurrentContestStatus !== ContestStatusLocal.ResultDeclared" class="flex flex-row gap-2">
                          <div class="flex flex-row items-center gap-1">
                            <span>|</span>
                            <span class="text-brand-color">{{ getTotalFeedPostCount }}</span>
                            <span>Photos</span>
                          </div>
                          <span>|</span>
                          <div class="flex flex-row gap-1 items-center">
                           <span class="text-brand-color">{{ totalVoteCount }}</span>
                            <span>Votes</span></div>
                        </div>
                        </div>
                   </div>
                    <div class="flex items-center gap-x-4 lg:gap-x-6">
                        <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"/>
                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative">
                            <MenuButton class="-m-1.5 flex items-center ">
                                <span class="sr-only">Open menu</span>
                                <ProfilePicture :key="getImgUrl(getLoggedInUser)"
                                                :name="getUserFullName(getLoggedInUser)"
                                                :image-url="getImgUrl(getLoggedInUser)" :size="8"/>

                                <span class="hidden lg:flex lg:items-center">
                  <span class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true">{{ getUserFullName(getLoggedInUser) }}</span>
                  <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>

                </span>
                            </MenuButton>
                            <transition enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                        class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                        <a @mousedown="item.action"
                                           :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']">
                                            {{ (item.name) }}
                                        </a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <main>
                <div class="bg-slate-50">
                    <slot/>
                </div>
            </main>
            <!--      class="fixed left-0 w-screen z-30 bottom-0"-->
            <footer v-if="footerVisible"
                    class="bg-white text-gray-900 px-4 lg:px-7 shadow-inner fixed right-0  bottom-0 left-0 lg:left-72">
                <div class="flex flex-col md:flex-row items-center justify-between my-auto py-3">
                    <div class="flex flex-row justify-center items-center text-xs">
                        <p>{{ ('Powered by') }}</p>
                        <img class="h-5 w-auto ml-3 mr-1" src="~/assets/icon/app.png" alt=""/>
                        <p>Aphelia.io</p>
                    </div>
                    <div class="flex flex-row justify-center items-end md:items-center text-xs">
                      <span class="leading-none">{{ today }}. All rights reserved.</span>
                      <a href="https://technuf.com/" target="_blank"><img class="h-6 w-auto ml-3 mr-1 mb-0 md:mb-3" src="~/assets/icon/logo-dark.svg" alt=""/></a>
                    </div>
                </div>
            </footer>

        </div>
    </div>
    <Notification/>
    <PostUploadingToast/>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {
    Bars3Icon,
    BellIcon,
    HomeIcon,
    ClipboardIcon,
    XMarkIcon,
    DocumentTextIcon, ArrowRightOnRectangleIcon
} from '@heroicons/vue/24/outline'

import {
    ChevronDownIcon,
    Squares2X2Icon
} from '@heroicons/vue/20/solid'
import {userStore} from "~/store/userStore";
import type {User} from "~/models/user";
import {AppRoutes} from "~/utils/other/AppRoutes";
import {clubStore} from "~/store/clubStore";
import {gradeStore} from "~/store/gradeStore";
import {useRoute, useRouter} from "vue-router";
import {companyStore} from "~/store/companyStore";
import {sessionStore} from "~/store/sessionStore";
import moment from "moment";
import {onMounted} from "vue";
import {storeToRefs} from "pinia";
import NewFeedPostToast from "~/components/NewFeedPostToast.vue";
import type {FeedPost} from "~/models/FeedPost";
import {FeedPostStatus} from "~/utils/enums/FeedPostStatus";
import {contestStore} from "~/store/contestStore";
import {ContestStatusLocal} from "~/utils/enums/ContestStatusLocal";
//bg-slate-50
useHead({bodyAttrs: {class: ' h-full '}, htmlAttrs: {class: 'h-full bg-white'}})

const useUserStore = userStore();
const useClubStore = clubStore();
const useGradeStore = gradeStore();
const useContestStore = contestStore()

const useCompanyStore = companyStore();
const useSessionStore = sessionStore();
const route = useRoute();
const router = useRouter();
const {clearContestStore} = useContestStore;
const {totalVoteCount, getFeedPostCount, getTotalFeedPostCount, getCurrentContest, getCurrentContestStatus, getViewsCount} = storeToRefs(useContestStore);
const {getLoggedInUser, getSidebarNavigations} = storeToRefs(useUserStore);
const {clearUserStore} = useUserStore
const {clearClubStore} = useClubStore
const {clearGradeStore} = useGradeStore
const {clearCompanyStore} = useCompanyStore
const {clearSessionStore} = useSessionStore
const {getFeedCompany} = storeToRefs(useCompanyStore)
const {getCurrentClub} = storeToRefs(useClubStore)
const {isLoggedInUserAnAdminOfThisCompany} = storeToRefs(useUserStore)
let today = moment().format('YYYY')

const currentPath = ref<String>('');
const footerVisible = ref<boolean>(false);

watch(route, value => {
    currentPath.value = value.path;
}, {deep: true, immediate: true},)

const navigation = ref<any[]>([])
const teams = [
    {id: 1, name: 'Payment History', href: '#', initial: 'P', current: false},
    {id: 2, name: 'Invoice History', href: '#', initial: 'I', current: false},

]
const userNavigation = [
    // {name: 'Dashboard', action: goToDashboardPage},
    {name: 'My profile', action: goToMyProfilePage},
    {name: 'Sign out', action: onLogout},
]

const sidebarOpen = ref(false)

function getUserFullName(user: User) {
    return ((user?.FirstName ?? '') + ' ' + (user?.LastName ?? '')).trim();
}

function getUserFirstName(user: User) {
    return (user?.FirstName ?? '').trim();
}

function hasImgUrl(user: User) {
    return (user?.profilePicture ?? '').trim().length > 0;
}

function getImgUrl(user: User) {
    return user?.profilePicture?.trim() ?? '';
}

async function goToMyProfilePage() {
    await router.push(AppRoutes.AdminMyProfile);
}

async function goToDashboardPage() {
    // await router.push(AppRoutes.ParentDashboard);
}

const isSelectedMenu = (currentPath: string, href: string) => {
    if (currentPath.startsWith('/rsvp') && (href == '/events')) {
        return true;
    } else {
        return currentPath.startsWith(href);
    }
}

async function onLogout() {
    try {
        const companyCode = ''+getFeedCompany.value.code;
        const clubId = ''+getCurrentClub.value.id;
        clearClubStore();
        clearGradeStore();
        clearSessionStore();
        clearContestStore();
        // clearCompanyStore();
        clearUserStore();
        navigateTo('/feed/'+companyCode+'/'+clubId, {replace: true, external: true })
    } catch (e) {
        console.log(e)
    }
}

const populateLeftMenus = () => {
    navigation.value = [];
}
function isAddNewContest(){
  const currentPath = route.path;
 return currentPath.endsWith('/new-contest');
}
function showVotingCount(){
  const firstContest = getCurrentClub.value.Contests[0];
  if (firstContest.EndDateTime){

  }
}

const getTitle = () => {
    try {
        const currentPath = route.path;
        if (currentPath.startsWith('/feed')) {
            footerVisible.value = false;
            // return getCurrentClub.value.ClubName;
            const firstContest = getCurrentClub.value.Contests[0];
            if (firstContest) {
                return getCurrentClub.value.Contests[0]?.Name;
            } else {
                return getCurrentClub.value.ClubName;
            }
        } else {
            footerVisible.value = true;
            return 'Welcome, ' + getUserFirstName(getLoggedInUser.value)
        }
    } catch (e) {
        return '';
    }
}

onMounted(() => {
    try {
      console.log(getTotalFeedPostCount.value)
        // populateLeftMenus();
    } catch (e) {
        console.log(e)
    }


})


</script>
